@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Raleway:wght@300;400;900&display=swap');


.home-Footer {
    display: flex;
    justify-content: space-around;
    padding: 2rem 0;
    flex-shrink: 0;
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(
      to bottom,
      rgba(12, 22, 34, 0.8),
      rgb(41, 97, 165)
    );
    background-size: 100% 300%;
  }
  
  .home-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
  }
  
  
  .home-logo > span:first-child {
    font-weight: 500;
    font-size: 1.6rem;
    color: white;
    text-transform: uppercase;
  }

  .home-logo > span:last-child {
    font-weight: normal;
    font-size: 1.2rem;
    color: white;
  }
  .home-logo > span:first-child {
    font-family: 'Montserrat', sans-serif;
  }

  .home-logo > span:last-child {
    font-family: 'Montserrat', sans-serif;
  }

  .home-social > span {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
  }
  .home-social {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: white;
    align-items: center;
    justify-content: center;
  }
  
  .home-social > span {
    font-size: 1.25rem;
    color: white;
  }
  
  .home-social .links{
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
  }

  .AiFillLinkedin:hover,
  .AiOutlineMail:hover {
    filter: drop-shadow(0 0 3px rgba(255, 255, 255, 1));
  }
  
  .head:hover{
    filter: drop-shadow(0 0 0.1px rgba(255, 255, 255, 1));
    cursor: default;

  }