.Banner {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to bottom, #0a1940, #0f315c);
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.left-b {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 3;
  color: #ffffff;
}

.right-b {
  flex: 2.5;
  position: relative;
}

.logo {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  margin-left: 4rem;
  margin-bottom: -1rem;
}

.logo > img {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
}

.left-b .logo > span {
  font-weight: bold;
  font-size: 3rem;
}

.about {
  background-color: transparent;
}

.about > span {
  font-size: 1.3rem;
  color: #f5f5f5;
  font-weight: 200;
  font-style: italic;
  animation: fade-in 1s ease-in-out 0s forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 980px) {
  .left-b {
    flex: 10000;
  }

  .left-b .logo > span {
    font-weight: bold;
    font-size: 2.4rem;
  }

  .about > span {
    font-size: 1.2rem;
    color: #f5f5f5;
    font-weight: 250;
    font-style: italic;
  }
}
