.Footer {
  display: flex;
  background: linear-gradient(to top, #0a1940, #133868ef);
  justify-content: space-around;
  padding: 2rem 0;
  flex-shrink: 0;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}

.logo > img {
  width: 5rem;
  height: 5rem;
  margin-bottom: 2rem;
}

.logo > span {
  font-weight: bold;
  font-size: 1.55rem;
  color: white;
  text-transform: uppercase;
}

.social {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: white;
  justify-content: center;
}

.social > span {
  font-size: 1.25rem;
  color: white;
}

.social > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.social > ul > li {
  font-size: 1.05rem;
  margin-bottom: 0.4rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.social > ul > li > a {
  color: white;
  text-decoration: none;
}

.social > ul > li > a:hover {
  font-size: 1.1rem;
  margin-bottom: 0.35rem;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
}

.connect {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.connect > span {
  font-size: 1.25rem;
  color: white;
}

.connect > button {
  background-color: #ffffff;
  color: #133868ef;
  font-size: 1.05rem;
  border: none;
  padding: 0.7rem 1.2rem;
  border-radius: 0.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.connect > button:hover {
  background-color: #f0f0f0;
}

.content {
  flex-grow: 1;
}
