@import url('https: //fonts.googleapis.com/css2?family=Inconsolata:wght@500&display=swap');

.SigmaSquad {
    height: 100vh;
    width: 100%;
    /*background-color: #00ffff;*/
}

.gap {
    margin-top: 7.5%;
}

.title-area{
    background-image: url("Sigma_Squad_Background.jpg");
    height: 70vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 250%;
    font-weight: bolder;
    font-family: 'Inconsolata, Times New Roman';
    color: rgb(255, 255, 255);
}

.IITT {
    font-size: 175%;
    font-weight: bolder;
    font-family: 'Inconsolata, Times New Roman';
    color: rgb(255, 255, 255);
}

.SSLogo {
    height: 25%;
}

.about-text {
    padding-top: 4vh;
}

.sep1 {
    height: 6px;
    width: 40px;
    border-radius: 4px;
    background-color: black;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sep2 {
    height: 6px;
    width: 40px;
    border-radius: 4px;
    background-color: white;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.SSAbout {
    display: flex;
    align-items: center;
    padding-top: 0.8vh;
    padding-left: 3vw;
    padding-right: 3vw;
}

.SSTeam {
    padding-top: 3vh;
}

.leads {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 2vh;
}

.heads{
    color: black;
    font-weight: 500;
    background-color: rgb(215, 215, 215);
    margin-bottom: 2vh;
    margin-left: 2vh;
    height: 10vh;
    min-width: 27vh;
    max-width: 27vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SSObj {
    margin-top: 2vh;
    color: white;
}

.objs{
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    align-items:center;
    font-weight: 400;
    color: black;
}

.SSFooter {
    color: aliceblue;
    font-size: large;
    font-weight: 700;
    background: linear-gradient(to right, blue, red);
    bottom: 0;
    width: 100%;
    height: auto;
}

.footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 3vh;
}

.socialMedia {
    display: flex;
    flex-direction: column;
    width: 33.33vw;
}

.accounts {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 2vh;
}

.socialMedia p{
    display: flex;
    justify-content: center;
    color: white;
}

.phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33.33vw;
}

.phone p{
    color: white;
}

.email {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33.33vw;
}

.email p{
    color: white;
}