@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Raleway:wght@300;400;900&display=swap');

*{
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  /* background-color: rgba(12, 22, 34, 255); */
}

a{
  text-decoration: none;
  color: rgb(18, 111, 114);

}

::-webkit-scrollbar{
  display: none;
}

.stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
