@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Raleway:wght@300;400;900&display=swap');

.ClubHead {
  position: relative;
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
  margin-bottom: 4rem;
  margin-top: 4rem;
}

.left-c {
  color: black;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}

.left-c .custom-hr1 {
  position: absolute;
  width: 14.5rem;
  border: 2.6px solid #2a4f7e;
  border-radius: 20%;
  margin: -10px 0;
}

.left-c > div {
  display: flex;
  gap: 1rem;
}

.left-c .title{
  color: black;
}

.left-c > div:nth-of-type(1) {
  font-size: 2.4rem;
}

.left-c > div:nth-of-type(2) {
  font-size: 2.3rem;
  font-weight: 600;
  font-style: italic;
  -webkit-text-stroke-width: 1px;
}

.right-c {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}


/* ignore the css below */


@media (max-width: 980px) {
  .ClubHead {
    padding: 0 1rem;
  }

  .left-c {
    font-size: 2rem;
  }

  .left-c .custom-hr1{
    width: 8rem;
  }

  .left-c > div:nth-of-type(1) {
    font-size: 2.4rem;
  }

  .left-c > div:nth-of-type(2) {
    font-size: 1rem;
  }

  .left-c .title {
    font-size: 1rem;
  }

  .left-c .title>span{
    font-size: 1.8rem;
  }
}
