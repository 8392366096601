.Programs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 2rem;
  color: black;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.mobile-header {
  display: none;
}

.Programs .programs-header {
  display: flex;
  gap: 10rem;
  font-weight: bold;
  font-size: 2.8rem;
  justify-content: center;
  overflow-wrap: normal;
  text-overflow: inherit;
  text-transform: uppercase;
  font-style: italic;
}

.program-categories {
  display: flex;
  gap: 1rem;
}

.category {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #273c75, #1a2855);
  padding: 2rem;
  color: #ffffff;
  gap: 1rem;
  justify-content: space-between;
  transition: background-color 0.3s ease;
  position: relative;
  border-radius: 8px;
}

.category::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

.category:hover::after {
  opacity: 1;
}

.category > :nth-child(1) {
  font-size: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: capitalize;
}

.category > :nth-child(2) {
  font-size: 1rem;
  line-height: 25px;
}

.join-now {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.join-now > img {
  width: 1rem;
}

.category:hover {
  cursor: pointer;
}

.stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1.6px;
  -webkit-text-stroke-color: black;
}

@media only screen and (max-width: 980px) {
  .program-categories {
    flex-direction: column;
  }

  .Programs .programs-header {
    display: flex;
    font-weight: bold;
    font-size: 1.8rem;
    color: white;
    overflow-wrap: normal;
    text-overflow: scroll;
    text-transform: uppercase;
    font-style: italic;
  }

  .programs-header > span {
    display: none;
  }

  .mobile-header {
    display: block;
    font-size: 2rem;
    font-weight: bold;
    color: #273c75;
    margin-bottom: 1rem;
  }
}
