.flex{
    display: flex;
    gap:1rem;
    padding-top: 2rem;
    align-items: baseline;
}
.Dsa-imgcontainer{
    padding-bottom: 1rem;
}
.DigitalWizard{
    padding: 2rem;
    padding-left: 5rem;
    color: rgb(35, 32, 32);
    display: flex;
    justify-content: space-between;
}
.Dsa-imgcontainer{
    margin-bottom: 0;
    height: 11rem;
}
.h-subtitle{
    max-width: 550px;
}
.dsa-start{
    color: rgb(35, 32, 32);
    margin-top: 0;
    font-size: 1.5rem;
    margin-bottom: 0rem;
}
.dsa_row1{
    width: 36rem;
    height: 9 rem;
}
.dsa_row1>img{
     width: 100%;
    height:100%;
}
/* //programs */
.dsaprog-list{
    display: flex;
    justify-content: center;
    gap:1.5rem;
    padding: 10px;
}
.dsalist{
    background: rgb(62, 60, 60);
    padding:25px;
    display: flex;
    flex-direction: column;
    gap:1rem;
    color: whitesmoke;
    align-items: flex-start;
    text-align: left;
    
}
.dsa-left{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap:0.6rem;
}
.dsalist:hover{
    background: rgb(31, 30, 28);
    cursor: pointer;
}
.p-content{
    max-width: 14rem;
    min-height: 7rem;
}
.p-head{
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}
.dsajoin{
    gap:1rem;
    display: flex;
   align-items: center;
   
  }
  .dsajoin img{
    width:1.5rem;
    height: 1.2rem;
   
  }
  /* program head */
  .stroke-text{
    color:transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: rgb(10, 10, 10);
  }
  .pro-head{
    display: flex;
    font-size: 3.3rem;
    text-align: center;
    justify-content: center;
    align-items: baseline;
    gap: 3.5rem;
    padding:2rem;
    color: rgb(90, 88, 88);
}
.D-joins{
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    padding: 1.4rem;
    gap:1rem;
   align-items: center;
   text-align: center;
   background: rgb(41, 40, 40);
   border-radius: 10px;
   min-width: 60rem;
  
}
