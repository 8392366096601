.achievements {
  margin-top: 2rem;
}

h2 {
  font-size: 2rem;
  font-weight: 510;
  letter-spacing: 0.86px;
  text-align: center;
  color: #133868ef;
  transition: color 0.3s ease;
  text-transform: uppercase;
}

h2:hover {
  color: #0962b1;
}

.achievement-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

.achievement {
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;
}

.achievement:hover {
  background-color: #e1e1e1;
}

h3 {
  font-size: 1.5rem;
  color: #133868ef;
  transition: color 0.3s ease;
}

.achievement:hover h3 {
  color: #0962b1;
}

p {
  color: #666666;
  font-size: 1rem;
}

.achievement:hover p {
  color: #444444;
}

@media only screen and (max-width: 980px) {
  .achievement-grid {
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }

  .achievement > h3 {
    margin-bottom: 1rem;
  }
}
