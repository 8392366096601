@import url('https: //fonts.googleapis.com/css2?family=Inconsolata:wght@500&display=swap');

.Techmaniacs {
    height: 100vh;
    width: 100%;
}

.gap {
    margin-top: 7.5%;
}

.titleArea{
    background-image: url('3d-robot-hand-background-ai-technology-side-view_53876-129789.jpg');
    height: 65vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 250%;
    font-weight: bolder;
    font-family: 'Inconsolata, Times New Roman';
    color: rgb(255, 255, 255);
}

.IITT {
    font-size: 175%;
    font-weight: bolder;
    font-family: 'Inconsolata, Times New Roman';
    color: rgb(255, 255, 255);
}

.TechmaniacsLogo {
    height: 25%;
}

.TechAbout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-text {
    padding-top: 4vh;
}

.sep1 {
    height: 6px;
    width: 40px;
    border-radius: 4px;
    background-color: black;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sep2 {
    height: 6px;
    width: 40px;
    border-radius: 4px;
    background-color: white;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.TechAbout1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
    padding-top: 0.8vh;
    padding-left: 3vw;
    padding-right: 3vw;
}

.TechTeam {
    padding-top: 3vh;
}

.leads {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2vh;
    overflow: auto;
}

.leads div{
    background-color: rgb(233, 233, 233);
}

.heads{
    color: black;
    font-weight: 500;
    margin-bottom: 2vh;
    margin-left: 2vh;
    height: 10vh;
    min-width: 27vh;
    max-width: 27vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TechIG {
    margin-top: 2vh;
    background-image: linear-gradient(to bottom, #87cefa 0%, #b2ebf2 50%, #87cefa 100%);
}

.igs{
    margin-top: 6vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0;
    padding-top: 2vh;
}

.electronics, .arduino, .drones, .robotics {
    margin-bottom: 1.5vh;
    padding-top: 2vh;
    margin-left: 1.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 30vw;
    max-width: 30vw;
    background-color: rgb(187, 223, 238);
    border: solid 3px gold;
}

.electronics:hover, .arduino:hover, .robotics:hover, .drones:hover{
    background-color: rgb(211, 232, 241);
    cursor: pointer;
}

.electronicsClicked,
.arduinoClicked,
.dronesClicked,
.roboticsClicked {
    margin-bottom: 1.5vh;
    padding-top: 2vh;
    margin-left: 1.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50vw;
    max-width: 50vw;
    background-color: rgb(187, 223, 238);
    border: solid 3px gold;
    font-weight: 500;
}

.electronicsClicked:hover,
.arduinoClicked:hover,
.dronesClicked:hover,
.roboticsClicked:hover {
    background-color: rgb(211, 232, 241);
    cursor: pointer;
}

.electronics,
.arduino,
.drones,
.robotics p{
    font-weight: 600;
    font-size: large;
}

.electronicspic, .arduinopic, .dronespic, .roboticspic {
    height: 17vh;
}

.show {
    display: block;
    border: 1px solid black;
    background-color: rgb(247, 245, 241);
    font-weight: 300;
    padding: 0 0 0 0;
    min-width: 50vw;
    max-width: 50vw;
    max-height: 25vh;
    overflow-y: scroll;
}

.hide {
    display: none;
}

.TechFooter {
    color: aliceblue;
    font-size: large;
    font-weight: 700;
    background-color: rgb(49, 174, 237);
    bottom: 0;
    width: 100%;
    height: auto;
}

.footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 3vh;
}

.phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33.33vw;
}

.email {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33.33vw;
}

.socialMedia {
    display: flex;
    flex-direction: column;
    width: 33.33vw;
}

.accounts {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 2vh;
}