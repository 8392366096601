
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Raleway:wght@300;400;900&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
}
.About {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5rem 2.2rem;
  height: 100%;
}

.About > span {
  font-weight: bold;
  font-size: 2rem;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  margin-bottom: 1.4rem;
}

.About > p {
  color: rgba(255, 255, 255, 0.819);
  font-size: 1.232rem;
}

.About .hrdiv {
  position: absolute;
  width: 6rem;
  border: 2.4px solid #49c5b6;
  border-radius: 30%;
  margin: 2.5rem 0;
}
