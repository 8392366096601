.clubgrid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

.clubgrid > span:first-child {
  color: transparent;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.4rem;
  letter-spacing: 22px;
  font-weight: 900;
  text-transform: uppercase;
  color: white;
  font-style: normal;
}

.clubgrid > span {
  font-family: 'Montserrat', sans-serif;  
  color: white;
  font-size: 1.236rem;
  font-style: italic;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13.86rem, 1fr));
  grid-gap: 1.38rem;
  max-width: 46.14rem;
  margin: 0 auto;
  margin-top: 5.84rem;
  margin-bottom: 1.38rem;
  padding: 0 0.62rem;
}

.grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 7.69%;
  transition: 0.207s;
}

.grid-item img {
  width: 76.92%;
  height: auto;
  transition: 0.207s;
  border-radius: 7.69%;
}

.grid-item:hover img {
  transform: scale(0.6);
}

.grid-item.hovered .overlay {
  opacity: 1;
  transform: translateY(0);
}

.overlay {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 1, 1, 0);
  backdrop-filter: blur(29.2px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 0;
  transform: translateY(100%);
  transition: 0.207s;
  padding: 1rem;
}

.overlay h3 {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0.9rem;
  font-size: 1.0824rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #00a8e8;
  text-shadow: 1.38px 1.38px 2.76px rgba(0, 0, 0, 0.8);
}

.overlay p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  color: #fff;
  overflow: scroll;
}

.club-name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.69rem;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(6.9px);
  text-align: center;
}

.club-name p {
  font-size: 1.0824rem;
  color: #000;
}

@media only screen and (max-width: 800px) {
  .clubgrid {
    padding: 0 1rem;
  }
  .grid-container {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    padding: 0 3rem;
  }

  .grid-item {
    margin-bottom: 1rem;
    justify-content: center;
    align-items: ce;
  }

  .grid-item img {
    width: 100%;
    height: auto;
  }
}
